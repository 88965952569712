// src/App.js
import React from "react";
import Navbar from "./components/navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactPage from "./components/contactPage/ContactPage";

function App() {
  return (
    <div>
      <Navbar />
      <ContactPage />
    </div>
  );
}

export default App;
