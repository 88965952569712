// src/components/Navbar.js
import React from "react";
import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";
import "./Navbar.css";

const Navbar = () => {
  return (
    <BootstrapNavbar expand="lg" className="navbar fixed-top">
      <BootstrapNavbar.Brand href="#home">
        Sapphire General Trading LLC
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#services">Services</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>

          <Nav.Link href="#login" className="cta-button">
            Login
          </Nav.Link>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
